import React, {useState, useEffect, useCallback, Fragment} from 'react';
import {useOutlet} from 'reconnect.js';
import Row from '../Widgets/Row';
import Button from '../Widgets/Button';
import {message, Modal, Input, Select} from 'antd';
import {Close} from '@styled-icons/material/Close';
import Block from '../Widgets/Block';

export default function ManagerDialog({
  data,
  visible,
  onClose,
  onConfirm,
  onUpdate,
  EditMode = false,
}) {
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [mode, setMode] = useState('新增');

  const transformData = (data) => {
    return data.map((item) => ({label: item.name, value: item.id}));
  };

  const handleSelectChange = (value) => {
    onUpdate('roles', value);
  };

  const fetchRoleList = useCallback(async () => {
    setLoading(true);
    try {
      let {data} = await actions.fetchRoleList();
      setRoleList(transformData(data));
    } catch (err) {
      console.log('err', err);
      message.error('發生錯誤');
    }
    setLoading(false);
  }, [actions.fetchRoleList]);

  useEffect(() => {
    fetchRoleList();
  }, [fetchRoleList, visible]);

  useEffect(() => {
    if (EditMode) {
      setMode('編輯');
    }
  }, [EditMode]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closeIcon={
        <Close style={{position: 'absolute', right: 20, top: 20}} size={20} />
      }
      title={`${mode}管理員`}
      footer={
        <Row style={{justifyContent: 'flex-end'}}>
          <Button type="danger" onClick={onClose}>
            取消
          </Button>
          <Button type="primary" onClick={onConfirm}>
            確認
          </Button>
        </Row>
      }>
      <Block>
        {!EditMode && (
          <div className="row">
            <h4>管理者帳號</h4>
            <Input
              value={data.username}
              type="text"
              onChange={(e) => onUpdate('username', e.target.value)}
            />
          </div>
        )}
        <div className="row">
          <h4>管理者信箱</h4>
          <Input
            value={data.email}
            type="text"
            onChange={(e) => onUpdate('email', e.target.value)}
          />
        </div>
        <div className="row">
          <h4>管理者名稱</h4>
          <Input
            value={data.name}
            type="text"
            onChange={(e) => onUpdate('name', e.target.value)}
          />
        </div>
        {!EditMode && (
          <Fragment>
            <div className="row">
              <h4>密碼</h4>
              <Input
                value={data.password}
                type="password"
                onChange={(e) => onUpdate('password', e.target.value)}
              />
            </div>
            <div className="row">
              <h4>確認密碼</h4>
              <Input
                value={data.password_confirmation}
                type="password"
                onChange={(e) =>
                  onUpdate('password_confirmation', e.target.value)
                }
              />
            </div>
          </Fragment>
        )}
        <div className="row">
          <h4>角色</h4>
          <Select
            mode="multiple"
            allowClear
            placeholder="請選擇角色"
            onChange={handleSelectChange}
            options={roleList}
            defaultValue={data.roles}
          />
        </div>
      </Block>
    </Modal>
  );
}

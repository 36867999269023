import React, {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import moment from 'moment';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import BackButton from '../../Components/BackButton';
import {navigate} from 'gatsby';
import Row from '../../Widgets/Row';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import DeleteRoleDialog from '../../Dialogs/DeleteRoleDialog';
import ManagerDialog from '../../Dialogs/ManagerDialog';
import ManagerChangePasswordDialog from '../../Dialogs/ManagerChangePasswordDialog';

const appConfig = require('../../data.json');

const defaultAccountInfo = {
  email: '',
  name: '',
  roles: [],
};

export default function EditManagerPage({location}) {
  const {id: managerID} = qs.parse(location.search);
  const [manager, setManager] = useState({});
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [accountInfo, setAccountInfo] = useState({...defaultAccountInfo});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(
    false,
  );
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const fetchManagerDetail = useCallback(async () => {
    try {
      setLoading(true);
      let res = await actions.fetchManagerDetail(managerID);
      setAccountInfo({
        name: res.name,
        email: res.email,
        roles: res.roles.map((role) => role.id),
      });
      setManager(res);
    } catch (err) {
      console.warn(err);
    }
    setLoading(false);
  }, [managerID, actions.fetchManagerDetail]);

  const updateManagerViaID = async () => {
    try {
      setLoading(true);
      let res = await actions.updateManager({
        id: managerID,
        email: accountInfo.email,
        name: accountInfo.name,
        roles: accountInfo.roles,
      });
      Ant.message.success('編輯管理員成功');
      await fetchManagerDetail();
    } catch (err) {
      console.warn(err);
      Ant.message.error('刪除失敗');
    }
    setLoading(false);
  };

  const deleteManagerViaID = async () => {
    try {
      setLoading(true);
      let res = await actions.deleteManagerViaID(managerID);
      Ant.message.success('刪除管理員成功');
      navigate('/admin-user/');
    } catch (err) {
      console.warn(err);
      Ant.message.error('刪除失敗');
    }
    setLoading(false);
  };

  const setParantState = (key, value) => {
    setAccountInfo({...accountInfo, [key]: value});
  };

  const onDialogConfirm = async () => {
    await updateManagerViaID();
    setOpenEditDialog(false);
  };

  useEffect(() => {
    fetchManagerDetail();
  }, [fetchManagerDetail]);

  if (!manager) {
    return (
      <Wrapper>
        <BackButton />
        <h2>找不到此管理員資料</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton />
        <div style={{flex: 1}} />
        <Button
          style={{marginLeft: 10, background: '#69bfa3', color: 'white'}}
          onClick={() => setOpenChangePasswordDialog(true)}>
          修改密碼
        </Button>
        <Button
          style={{marginLeft: 10}}
          type="primary"
          onClick={() => setOpenEditDialog(true)}>
          編輯管理員
        </Button>
        <Button
          style={{marginLeft: 10}}
          type="danger"
          onClick={() => setOpenDeleteDialog(true)}>
          刪除管理員
        </Button>
        <Button
          shape="circle"
          style={{marginLeft: 8, border: 0}}
          onClick={fetchManagerDetail}
          icon={<Refresh color={appConfig.colors.main} size={26} />}
        />
      </Row>
      <Row style={{marginBottom: 20}}>
        <Block>
          <h2>管理員資訊</h2>
          <div>
            <div className="row">
              <h4>管理員編號</h4>
              <div># {manager.id}</div>
            </div>
            <div className="row">
              <h4>管理員帳號</h4>
              <div>{manager.username}</div>
            </div>
            <div className="row">
              <h4>管理員信箱</h4>
              <div>{manager.email}</div>
            </div>
            <div className="row">
              <h4>管理員名稱</h4>
              <div>{manager.name}</div>
            </div>
            <div className="row">
              <h4>角色</h4>
              {manager.roles?.length > 0 ? (
                <div>
                  {manager.roles.map((role) => (
                    <Ant.Tag key={role.id}>{role.name}</Ant.Tag>
                  ))}
                </div>
              ) : (
                <div>尚未指派角色</div>
              )}
            </div>
            <div className="row">
              <h4>建立時間</h4>
              <div>{moment(manager.created_at).format('YYYY-MM-DD HH:mm')}</div>
            </div>
            <div className="row">
              <h4>最後更新時間</h4>
              <div>{moment(manager.updated_at).format('YYYY-MM-DD HH:mm')}</div>
            </div>
          </div>
        </Block>
      </Row>
      <DeleteRoleDialog
        visible={openDeleteDialog}
        onConfirm={() => deleteManagerViaID()}
        onClose={() => setOpenDeleteDialog(false)}
        type="Manager"
      />
      <ManagerDialog
        data={accountInfo}
        visible={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        onConfirm={() => onDialogConfirm()}
        onUpdate={setParantState}
        EditMode
      />
      <ManagerChangePasswordDialog
        id={managerID}
        visible={openChangePasswordDialog}
        onClose={() => setOpenChangePasswordDialog(false)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;

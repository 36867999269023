import React from 'react';
import Row from '../Widgets/Row';
import Button from '../Widgets/Button';
import {Modal} from 'antd';

export default function DeleteRoleDialog(props) {
  const {visible, onClose, onConfirm, type = 'role'} = props;

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={`${type === 'role' ? '角色' : '管理員'}`}
      footer={
        <Row style={{justifyContent: 'flex-end'}}>
          <Button type="primary" onClick={onConfirm}>
            確認
          </Button>
          <Button type="danger" onClick={onClose}>
            取消
          </Button>
        </Row>
      }>
      <Row style={{marginBottom: 15}}>
        此操作不可復原，確認要刪除此{`${type === 'role' ? '角色' : '管理員'}`}？
      </Row>
    </Modal>
  );
}

import React, {useState} from 'react';
import {useOutlet} from 'reconnect.js';
import Row from '../Widgets/Row';
import Button from '../Widgets/Button';
import {Modal, Input, message} from 'antd';
import {Close} from '@styled-icons/material/Close';
import Block from '../Widgets/Block';

export default function ManagerChangePasswordDialog({id, visible, onClose}) {
  const [actions] = useOutlet('actions');
  const [data, setData] = useState({password: '', confirmPassword: ''});
  const [loading, setLoading] = useState(false);

  const validatePassword = () => {
    if (!data.password) {
      message.error('請輸入新密碼');
      return false;
    } else if (data.password.length < 6) {
      message.error('密碼長度必須至少為 6 個字元');
      return false;
    }

    if (!data.confirmPassword) {
      message.error('請再次輸入新密碼');
      return false;
    } else if (data.password !== data.confirmPassword) {
      message.error('兩次輸入的密碼不一致');
      return false;
    }
    return true;
  };

  const handleConfirmOnClick = async () => {
    if (validatePassword()) {
      try {
        setLoading(true);
        await actions.changeManagerPassword({
          id,
          password: data.password,
          password_confirmation: data.confirmPassword,
        });
        message.success('密碼修改成功');
      } catch (err) {
        console.warn(err);
        message.error('密碼修改失敗');
      }
      onClose();
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closeIcon={
        <Close style={{position: 'absolute', right: 20, top: 20}} size={20} />
      }
      title="修改密碼"
      footer={
        <Row style={{justifyContent: 'flex-end'}}>
          <Button type="danger" onClick={onClose}>
            取消
          </Button>
          <Button type="primary" onClick={handleConfirmOnClick}>
            確認
          </Button>
        </Row>
      }>
      <Block>
        <div className="row">
          <h4>請輸入新的密碼</h4>
          <Input
            value={data.password}
            type="password"
            onChange={(e) => setData({...data, password: e.target.value})}
          />
        </div>
        <div className="row">
          <h4>請再次輸入新密碼</h4>
          <Input
            value={data.password_confirmation}
            type="password"
            onChange={(e) =>
              setData({...data, confirmPassword: e.target.value})
            }
          />
        </div>
      </Block>
    </Modal>
  );
}
